<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon icon-left">이전 페이지</i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub join">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								{{t('10074')}}
							</h2>
							<p class="inner__text">
								{{t('10075')}}
							</p>
						</div>
						<div class="inner__wrap">
							<!-- style -->
							<div class="style">
								<div class="tag">
									<h3 class="tag__title">
										{{t('10076')}}
									</h3>
									<ul class="tag__list">
										<li v-for="item in styleList1"
										:key="item.LIKING_CD">
											<label>
												<input type="checkbox" class="chk blind" :value="item.LIKING_CD" v-model="selectedList1" @click="handle1Click">
												<span class="tag__text">
													#{{item.LIKING_NM}}
												</span>
											</label>
										</li>
									</ul>
								</div>
								<div class="tag">
									<h3 class="tag__title">
										{{t('10077')}}
									</h3>
									<ul class="tag__list">
										<li v-for="item in styleList2"
										:key="item.LIKING_CD">
											<label>
												<input type="checkbox" class="chk blind" :value="item.LIKING_CD" v-model="selectedList2" @click="handle2Click">
												<span class="tag__text">
													#{{item.LIKING_NM}}
												</span>
											</label>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered">
			<button type="button" class="btn" :class=" selectedList1.length > 0 && selectedList2.length? 'btn-submit': 'btn-disabled' " @click="submitStyle">추가정보 입력 완료</button>
		</div>
	</div>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TheNavigation></TheNavigation>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from "vuex"
import { computed, onMounted, ref } from '@vue/runtime-core'
import api from "@/urls/mmb02";
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {	
	components: {
		ErrorAlert,
	},
	setup: function(){
		const store = useStore();
    	const router = useRouter();
		
		const styleList1 = computed(() => store.state.mmb02.mmb02.filter(item => item.LIKING_FLAG === "01"));
		const styleList2 = computed(() => store.state.mmb02.mmb02.filter(item => item.LIKING_FLAG === "02"));
		const openErrorAlert = ref(false)
		const selectedList1 = ref([]);

		const handle1Click = (e) => {
			if(e.target.checked && selectedList1.value.length >= 5) {
				e.preventDefault();
			}
		}

		const selectedList2 = ref([]);

		const handle2Click = (e) => {
			if(e.target.checked && selectedList2.value.length >= 5) {
				e.preventDefault();
			}
		}

		const userData = computed(() => store.state.userData);


		onMounted(() => {
			store.dispatch("mmb02/fetchMmb02");
			api.getMmb02({mem_id: userData.value.mem_token}).then((data) => {
				selectedList1.value = data.data.Res_data.filter(item => item.LIKING_FLAG === "01").map(item => item.LIKING_CD);
				selectedList2.value = data.data.Res_data.filter(item => item.LIKING_FLAG === "02").map(item => item.LIKING_CD);
			});
		});		

		const submitStyle = () => {
			store.dispatch("mmb02/fetchMmb02_01", {
				mem_id: userData.value.mem_token,
				tag_cds: selectedList1.value.join(",") +","+ selectedList2.value.join(","),
			}).then(x=>{
				//console.log(x);

				if(x.data.Res_cd === -1){
					//alert(x.Res_msg);
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: x.Res_msg});
					openErrorAlert.value = true;
				}
				else if(x.data.Res_cd === "0000"){
					router.push("/favorite/Complete");
				}
			});
		}
	    const { t }= useI18n() //번역필수 모듈

		return {
			styleList1,
			styleList2,
			selectedList1,
			selectedList2,
			handle1Click,
			handle2Click,
			submitStyle,
			goBack: () => router.back(),
			openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			t,  //번역필수 모듈
		  i18n,
		}		
	},
	
	
}
</script>